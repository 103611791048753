import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import GraphQLErrorList from "../Blog/graphql-error-list";
import {
  mapEdgesToNodes,
  toPlainText,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
} from "../../lib/helpers";

const RecentPosts = ({ props, className }) => {
  const data = useStaticQuery(graphql`
    {
      posts: allSanityBlogs(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              _id
              title
            }
            title
            slug {
              current
            }
            _rawBody
          }
        }
      }
    }
  `);

  const truncate = (str) => {
    return str.length > 120 ? str.substring(0, 120) + "..." : str;
  };

  const errors = props;

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const blogNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <section className={`relative ${className || "mb-20 md:mb-32"}`}>
      <div className="absolute left-0 -top-18 hidden md:block">
        <StaticImage
          src="../images/1.0 Homepage/1.1 Cross Pattern.png"
          loading="lazy"
          width={207}
          className="h-full"
        />
      </div>
      <div className="container">
        <header className="mb-10 text-center md:mb-14">
          <h2>The Think Tank</h2>
        </header>
        <div className="grid gap-y-8 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
          {blogNodes.length > 0 &&
            blogNodes.slice(0, 3).map((node) => (
              <div key={node.id}>
                <Link
                  to={`/${node.slug.current}/`}
                  className="block h-full no-underline"
                >
                  <div className="group">
                    {node.featuredImage && node.featuredImage.asset && (
                      <div className="mb-6 overflow-hidden">
                        <GatsbyImage
                          image={node.featuredImage.asset.gatsbyImageData}
                          alt={node.title}
                          className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                        />
                      </div>
                    )}
                    <div>
                      {node.categories && (
                        <>
                          {node.categories.slice(0, 1).map((category) => (
                            <div
                              className="mb-2.5 font-heading text-sm font-light uppercase tracking-wide text-gray-300"
                              key={category._id}
                            >
                              {category.title}
                            </div>
                          ))}
                        </>
                      )}
                      <p className="heading-five">{node.title}</p>
                      {node._rawBody && (
                        <p className="mb-0 font-normal text-typography-body">
                          {truncate(toPlainText(node._rawBody))}
                        </p>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default RecentPosts;
